import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import VerifyUrl from "./App";
import reportWebVitals from "./reportWebVitals";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Router>
		<Routes>
			<Route exact path='/:id' element={<VerifyUrl />} />
		</Routes>
	</Router>,
);

// If you want to start measuring performance in your app, pass a function jkbnjknj
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
