import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import RedirectTracker from "./RedirectTracker";

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function VerifyUrl() {
	const { id } = useParams();
	const query = useQuery();
	// const e = query.get("id");
	const p1 = query.get("p1");
	const p2 = query.get("p2");
	const p3 = query.get("p3");
	const p4 = query.get("p4");

	return (
		<RedirectTracker
			id={id}
			dataObject={{
				p1,
				p2,
				p3,
				p4,
			}}
		/>
	);
}

export default VerifyUrl;
